export function getCorsUrl(url) {
  return url;
}

export function getObjectFromUrl(url: string) {
  if (!url || !url.length) return;
  const s3PrefixPattern = /https?\:\/\/.*\..*\.amazonaws\.com\//;
  return url.replace(s3PrefixPattern, "").split("?")[0];
}

export async function fetchInstrumentUrls(
  userId,
  projectId,
  section,
  shuffle,
  projectVersion,
  genre
) {
  const url = `/apis/instrument_urls`;
  const res = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      email_id: userId,
      project_id: projectId,
      section_number: section,
      shuffle_count: shuffle,
      project_version: projectVersion,
      genre: genre,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());

  return res.task_id as string;
}

export function updateDownloadedTracks(emailId, trackData) {
  const url = `/apis/update_downloaded_tracks`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email_id: emailId,
      project_id: trackData.projectId,
      section_urls: trackData.sectionUrls,
      format: trackData.format,
      mode: trackData.mode,
      download_metadata: trackData.downloadMetadata,
      version: trackData.version,
      create_download: true,
    }),
  });
}

export function checkDownloadStatus(emailId, trackData) {
  const url = `/apis/update_downloaded_tracks`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email_id: emailId,
      project_id: trackData.projectId,
      section_urls: trackData.sectionUrls,
      format: trackData.format,
      download_metadata: trackData.downloadMetadata,
      create_download: false,
    }),
  });
}

export async function getSignedUrl(objectId) {
  if (!objectId) return;
  let queryObjectId = getObjectFromUrl(objectId.replace(/%2C/gi, ","));
  if (!queryObjectId) return Promise.resolve();
  const url = `/apis/media_url`;
  const signedUrlRes = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      object_ids: [queryObjectId],
    }),
  }).then((res) => res.json());

  return signedUrlRes[queryObjectId];
}

export async function deleteProject(userId, trackId) {
  return await fetch("/apis/delete_project", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      project_id: trackId,
      email_id: userId,
    }),
  });
}

export async function generateTrack(userId, track, useAlphaAPI) {
  let headers = {
    "Content-Type": "application/json",
  };

  let url = `/apis/map_module/tracks/generate/${track.uuid}`;

  const trackResponse = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({
      ...track,
      email_id: userId,
    }),
  }).then((res) => res.json());

  return trackResponse;
}

export async function getUserInfo(userId) {
  const userInfo = await fetch(
    `${process.env.NEXT_PUBLIC_SITE_URL}/apis/get_user_info/${userId}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return userInfo;
}

export async function getUserInfoDirect(userId) {
  const userInfo = await fetch(
    `http://${process.env.UPSTREAM_HOST}:${process.env.UPSTREAM_PORT}/api/get_user_info/${userId}`,
    {
      headers: {
        "x-beatoven-user-email": userId,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return userInfo;
}

export async function fetchPreviewTracks() {
  const urls = await fetch("/apis/get/preview_tracks")
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return urls;
}

export async function sendRequestedGenre(userEmail, requestedGenre) {
  return await fetch(`/apis/analytics/data`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email_id: userEmail,
      requested_genre: requestedGenre,
    }),
  }).then((res) => res.json());
}

export async function resetPassword() {
  return await fetch("/api/resetPassword", { method: "POST" }).then((res) =>
    res.json()
  );
}

export async function sendVerificationEmail() {
  return await fetch("/api/verifyEmail").then((res) => res.json());
}

export function cancelTrackGeneration(trackId) {
  const url = `/apis/tracks/cancel/${trackId}`;
  return fetch(url, {
    method: "POST",
  }).then((res) => {
    if (res.status == 200) {
      return { status: "Cancelled " };
      //res.send()
    } else {
      return {};
    }
  });
}

export function requestProAccess(userEmail: string) {
  return fetch("/apis/tier/get_access", {
    method: "POST",
    body: JSON.stringify({
      user_email: userEmail,
    }),
  });
}

export async function getPaymentUrl(planDetails: {
  userEmail: string;
  tierName: string;
  tierDuration: string;
  country: string;
  promoCode: string | null;
}) {
  return await fetch("/apis/payment/create-checkout-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_email: planDetails.userEmail,
      tier_name: planDetails.tierName,
      country: planDetails.country,
      tier_duration: planDetails.tierDuration,
      promo_code: planDetails.promoCode,
    }),
  });
}

export async function getOnetimePaymentUrl(planDetails: {
  userEmail: string;
  units: number;
  promoCode: string | null;
}) {
  return await fetch("/apis/payment/pay-for-download", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_email: planDetails.userEmail,
      units: planDetails.units,
      promo_code: planDetails.promoCode,
    }),
  });
}

export async function removeSubscription(userEmail: string) {
  return await fetch("/apis/payment/cancel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_email: userEmail,
    }),
  });
}

export async function applyPromoCode(promoCode: string) {
  const response = await fetch("/apis/apply_promo_code", {
    method: "POST",
    body: JSON.stringify({
      promo_code: promoCode,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

  return response;
}

export async function createTeam(teamName, teamMembers) {
  const response = await fetch("/apis/teams", {
    method: "POST",
    body: JSON.stringify({
      team_data: {
        team_name: teamName,
        members: teamMembers.map((m) => ({
          id: m,
          role: "editor",
        })),
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());
  return response;
}

export async function updateTeam(teamId, changes) {
  const response = await fetch(`/apis/teams/${teamId}`, {
    method: "PUT",
    body: JSON.stringify({
      team_data: changes,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());

  return response;
}

export async function deleteTeam(teamId) {
  const response = await fetch(`/apis/teams/${teamId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function submitTrackFeedback(feedbackObj) {
  const response = await fetch(`/apis/track_feedback/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(feedbackObj),
  }).then((r) => r.json());

  return response;
}

export async function fetchUsage() {
  const urls = await fetch("/apis/usage")
    .then((response) => response.json())
    .then((data) => {
      return data.usage;
    });
  return urls;
}

export async function fetchTrackVersion(trackId: string, version: number = 0) {
  return fetch(`/apis/tracks/${trackId}?version=${version}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (!res.ok) return Promise.reject("Failed to fetch tracks");
    return res.json();
  });
}

export async function updateProjectPrompt(trackId: string, prompt: string) {
  const formDataNew = new FormData();
  formDataNew.append("metadata", JSON.stringify({ prompt }));

  const trackUpdateResponse = await fetch(`/apis/tracks/${trackId}`, {
    method: "PUT",
    body: formDataNew,
  });

  if (!trackUpdateResponse.ok)
    return Promise.reject("Failed to update project prompt");
  return trackUpdateResponse.json();
}

export async function fetchTrackData(storedTrackData, version?: number) {
  let url = `/apis/tracks/${storedTrackData.uuid}`;
  if (typeof version !== "undefined") {
    url = `/apis/tracks/${storedTrackData.uuid}?version=${version}`;
  }
  const trackResponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!trackResponse.ok) return Promise.reject("Failed to fetch track data");
  return trackResponse.json();
}

export async function fetchTrackHistory(trackId: string, optionNumber: number) {
  const url = `/apis/tracks/${trackId}/all?option_number=${optionNumber}`;
  const trackResponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!trackResponse.ok) return Promise.reject("Failed to fetch track data");
  return trackResponse.json();
}

export async function getLatestTrackVersion(trackId: string) {
  return fetchTrackData(trackId)
    .then((track) => track.metadata.version)
    .catch((e) => Promise.reject("Failed to fetch latest track version"));
}

export async function shareTrack(trackData) {
  const url = `/apis/track/share`;
  const trackResponse = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(trackData),
  });
  if (!trackResponse.ok) {
    return Promise.reject("Failed to share track");
  } else {
    return trackResponse.json();
  }
}

export async function getSharedTrackData(shareId) {
  const url = `/apis/track/share/${shareId}`;
  const trackResponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!trackResponse.ok) {
    return Promise.reject("Failed to get shared track");
  } else {
    return trackResponse.json();
  }
}

export async function searchLoops(
  prompt,
  sources,
  count,
  searchMode,
  audioFile,
  videoFile
) {
  let url = `/apis/search_loops?prompt=${prompt}&count=${count}&search_mode=${searchMode}`;

  if (sources) {
    url = `${url}&sources=${sources}`;
  }

  let trackResponse: Response;

  if (audioFile || videoFile) {
    const formData = new FormData();

    if (audioFile) {
      formData.append("reference_audio", audioFile);
    }

    if (videoFile) {
      formData.append("reference_video", videoFile);
    }

    trackResponse = await fetch(url, {
      method: "POST",
      body: formData,
    });
  } else {
    trackResponse = await fetch(url, {
      method: "GET",
    });
  }

  return trackResponse.json();
}

export async function fetchTrackInstruments(trackId) {
  const url = `/apis/tracks/${trackId}/instrument_urls`;
  const trackResponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!trackResponse.ok) return Promise.reject("Failed to fetch track data");
  return trackResponse.json();
}

export async function createAPIToken() {
  const response = await fetch("/apis/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) return Promise.reject("Failed to create API token");
  return response.json();
}

export async function refreshAPIToken(currentToken: string) {
  const endpoint =
    "https://public-api.beatoven.ai/api/v1/users/refresh_api_key";
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
  });
  if (!response.ok) return Promise.reject("Failed to refresh API token");
  return response.json();
}

export async function getAPIUsage(token: string, grain: "day" | "month") {
  const endpoint = `https://public-api.beatoven.ai/api/v1/users/usage?grain=${grain}`;
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) return Promise.reject("Failed to get API usage");
  return response.json();
}

export async function getAPIUser(token: string) {
  const endpoint = "https://public-api.beatoven.ai/api/v1/users/me";
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) return Promise.reject("Failed to get API user");
  return response.json();
}
