import "../styles/globals.css";
import "../styles/slider.css";
import type { AppProps } from "next/app";
import UserLayout from "../components/userlayout";
import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import flagsmith from "flagsmith/isomorphic";
import { SessionProvider as Provider } from "next-auth/react";
import { getSession } from "next-auth/react";

import dynamic from "next/dynamic";

const PHProvider = dynamic(() => import("./posthog.js"), {
  ssr: false,
});

const PostHogPageView = dynamic(() => import("./postHogPageView"), {
  ssr: false,
});

function ErrorFallback(err) {
  return (
    <div className="relative pt-8 p-3 flex flex-col justify-center align-center place-content-center place-items-center text-neutral-400 ml-auto mr-auto max-h-screen h-screen max-w-2xl">
      <h2 className="text-lg text-neutral-200 font-mulish w-full">
        Oops! we noticed an unexpected beat that crashed the application.
      </h2>
      <p className="text-xs text-neutral-400">
        Our team has been notified of this error and they will investigate it.
        In the meantime, please try reloading this page and if the issue still
        persists, please hit "Report Bug" at the bottom of this screen or write
        to hello@beatoven.ai
      </p>
      <a
        href="/workspace"
        className="px-12 bg-green-400 rounded hover:bg-green-500 text-neutral-800 font-semibold py-3 mt-5"
      >
        Go To Workspace
      </a>
    </div>
  );
}

function MyApp({ Component, pageProps, err }: AppProps) {
  const [globalState, setGlobalState] = useState({});

  return (
    <Provider session={pageProps && pageProps.session}>
      <PHProvider>
        <PostHogPageView
          session={pageProps.session}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <UserLayout {...pageProps} err={err} globalState={globalState}>
          <Sentry.ErrorBoundary fallback={<ErrorFallback err={err} />}>
            <Component
              {...pageProps}
              globalState={globalState}
              setGlobalState={setGlobalState}
              err={err}
            />
          </Sentry.ErrorBoundary>
        </UserLayout>
      </PHProvider>
    </Provider>
  );
}

export default MyApp;
