import { TrackSections } from "./types";

export function convertSeconds(s) {
  let mins = Math.floor(s / 60);
  let seconds = Math.floor(s % 60);

  if (mins < 10) {
    mins = `0${mins}`;
  }

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${mins}:${seconds}`;
}

export function convertToSeconds(time) {
  const parts = time.split(":");
  let totalSeconds = 0;
  for (let i = 0; i < parts.length; i++) {
    totalSeconds += Number(parts[i]) * Math.pow(60, parts.length - i - 1);
  }
  return totalSeconds;
}

export function serializeSections(sections: TrackSections): TrackSections {
  return sections.map((section) => ({
    ...section,
    start: section.start * 1000,
    length: section.length * 1000,
  }));
}

export function deserializeSections(sections) {
  return sections.map((section) => ({
    ...section,
    start: section.start / 1000,
    length: section.length / 1000,
    shuffle_count: section.shuffle_count || 0,
    locked: section.locked || false,
  }));
}

const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

export function timeAgo(date) {
  let today = new Date();
  let currDate = new Date(date);
  let seconds = Math.floor((today.getTime() - currDate.getTime()) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds) || {
    label: "second",
    seconds: 1,
  };
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
}

export function getFadedVolumeCurve(start, end) {
  const FADE_INTERVAL = 3;

  return [
    { t: start, level: 0 },
    { t: start + FADE_INTERVAL, level: 1 },
    { t: Math.max(FADE_INTERVAL, end - FADE_INTERVAL), level: 1 },
    { t: end, level: 0 },
  ];
}

export function formatDateTime(createdAt: string) {
  const dateObj = new Date(createdAt);
  const now = new Date();

  const isToday = dateObj.toDateString() === now.toDateString();

  const hours = dateObj.getHours() % 12 || 12; // Adjust 0 to 12
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const ampm = dateObj.getHours() >= 12 ? "PM" : "AM";

  const timeFormatted = `${hours}:${minutes} ${ampm}`;

  if (isToday) {
    return `TODAY, ${timeFormatted}`;
  } else {
    // Format as DD/MM/YY
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year} ${timeFormatted}`;
  }
}
